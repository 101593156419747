import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { changepassword } from '../../services/changepasswordadmin';
import Swal from 'sweetalert2';

// Define validation schema
const validationSchema = Yup.object({
//     currentPwd: Yup.string()
//         .min(8, 'Current password must be at least 8 characters')
//         .matches(/[A-Z]/, 'Current password must contain at least one uppercase letter')
//         .matches(/[a-z]/, 'Current password must contain at least one lowercase letter')
//         .matches(/[0-9]/, 'Current password must contain at least one number')
//         .matches(/[\W_]/, 'Current password must contain at least one special character')
//         .required('Current password is required'),
//     newPwd: Yup.string()
//         .min(8, 'New password must be at least 8 characters')
//         .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
//         .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
//         .matches(/[0-9]/, 'New password must contain at least one number')
//         .matches(/[\W_]/, 'New password must contain at least one special character')
//         .required('New password is required'),

currentPwd: Yup.string()
.required('Current password is required'),
newPwd: Yup.string()
.required('New password is required'),

 });



const ChangePassword = () => {

    const handleSubmit = async (values, { resetForm }) => {

        const response = await changepassword.submitchangepassword({
            currentPwd: values.currentPwd,
            newPwd: values.newPwd,
        });
        try {
          
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'success',
                  title: 'Password changed successfully!',
                  showConfirmButton: false,
                  timer: 3000
                });
                resetForm();
              
        } catch (error) {
           // console.log(response)
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: response.apiError,
                showConfirmButton: false,
                timer: 3000
              });
              resetForm();
        }
    };

    return (
        <div className='pt-5'>
            <ToastContainer />
            <div className='container'>
                <div className='w-80 px-5'>
                    <Formik
                        initialValues={{
                            currentPwd: '',
                            newPwd: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <FormikForm>
                                <Form.Group className="mb-3" controlId="currentPwd">
                                    <Form.Label>Current Password</Form.Label>
                                    <Field
                                        name="currentPwd"
                                        type="password"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="currentPwd" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="newPwd">
                                    <Form.Label>New Password</Form.Label>
                                    <Field
                                        name="newPwd"
                                        type="password"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="newPwd" component="div" className="text-danger" />
                                </Form.Group>
                                <div className='d-flex  justify-content-between'>
                                    <div className=''>
                                        <Button type="submit" variant="primary">Submit</Button>

                                    </div>
                                    <div className='justify-text-end'>Forgot Password</div>
                                </div>
                            </FormikForm>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
