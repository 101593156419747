import React, { useEffect, useState } from "react";
import CashPowerDailySalesChart from "../../components/admin/charts/CashPowerDailySalesChart";
import CashPowerDailySalesChartByLocation from "../../components/admin/charts/CashPowerDailySalesChartByLocation";
import {
  airPowerDailySale,
  airPowerMonthlySale,
  cashPowerDailySale,
  cashPowerMonthlySale,
  transactionsAirTimesCount,
  transactionsCount,
  vendorBalance,
} from "../../services/dashboard.service";

const Dashboard = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try {
      const [
        vendor,
        cashDailySale,
        cashMonthlySale,
        tranCount,
        airDailySale,
        airMonthlySale,
        tranAirTimesCount,
      ] = await Promise.all([
        vendorBalance(),
        cashPowerDailySale(),
        cashPowerMonthlySale(),
        transactionsCount(),
        airPowerDailySale(),
        airPowerMonthlySale(),
        transactionsAirTimesCount(),
      ]);

      // console.log('Vendor:', vendor);
      //console.log('CashpowerDailySaledata:', cashDailySale?.data?.DailySales);
      //// console.log('CashMonthlySale:', cashMonthlySale?.data?.monthlySales);
      //console.log('Transaction Count:', tranCount?.data?.transactionCount);
      //console.log('Air Daily Sale:', airDailySale);
      //  console.log('Air Monthly Sale:', airMonthlySale);
      //  console.log('Transaction Air Times Count:', tranAirTimesCount);


      setData({
        vendor,
        cashDailySale,
        cashMonthlySale,
        tranCount,
        airDailySale,
        airMonthlySale,
        tranAirTimesCount
      })
    } catch (error) { }
  };
  const {
    vendor,
    cashDailySale,
    cashMonthlySale,
    tranCount,
    airDailySale,
    airMonthlySale,
    tranAirTimesCount,
  } = data;
  return (
    <div className="py-3">
      <div>
        {/* <h4>Newec Balance: </h4> */}
        <h6>Cash Power</h6>
      </div>
      <div className="py-2 row">
        <div className="col-md-4 col-sm-12 mb-2">
          <div className=" d-flex br-20 align-items-center p-3" style={{ background: "#fea223" }}>
            <span className="p-2 bg-light rounded-circle">
              <svg
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_47_1684)">
                  <path
                    d="M8.8601 13.3136H9.13378C10.1875 13.3136 11.0495 14.1757 11.0495 15.2293V24.8082C11.0495 25.8618 10.1875 26.7239 9.13378 26.7239H8.8601C7.80643 26.7239 6.94434 25.8618 6.94434 24.8082V15.2293C6.94434 14.1757 7.80643 13.3136 8.8601 13.3136ZM16.5232 7.56628C17.5768 7.56628 18.4389 8.42838 18.4389 9.48205V24.8082C18.4389 25.8618 17.5768 26.7239 16.5232 26.7239C15.4695 26.7239 14.6074 25.8618 14.6074 24.8082V9.48205C14.6074 8.42838 15.4695 7.56628 16.5232 7.56628ZM24.1862 18.5135C25.2399 18.5135 26.102 19.3756 26.102 20.4293V24.8082C26.102 25.8618 25.2399 26.7239 24.1862 26.7239C23.1325 26.7239 22.2704 25.8618 22.2704 24.8082V20.4293C22.2704 19.3756 23.1325 18.5135 24.1862 18.5135Z"
                    fill="#F02629"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_47_1684">
                    <rect
                      width="32.8417"
                      height="32.8417"
                      fill="white"
                      transform="translate(0.102539 0.724243)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <div className="ms-3">
              <span className="text-white fw-500">Sales</span>
              <h4 className="text-white">GMD &nbsp;{cashDailySale?.data?.DailySales ?? 'Loading...'}</h4>

              <span className="text-white fw-500">Cash Power Daily Sale</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mb-2">
          <div className=" d-flex br-20 align-items-center p-3" style={{ background: "#b64645 " }}>
            <span className="p-2 bg-light rounded-circle">
              <svg
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_47_1684)">
                  <path
                    d="M8.8601 13.3136H9.13378C10.1875 13.3136 11.0495 14.1757 11.0495 15.2293V24.8082C11.0495 25.8618 10.1875 26.7239 9.13378 26.7239H8.8601C7.80643 26.7239 6.94434 25.8618 6.94434 24.8082V15.2293C6.94434 14.1757 7.80643 13.3136 8.8601 13.3136ZM16.5232 7.56628C17.5768 7.56628 18.4389 8.42838 18.4389 9.48205V24.8082C18.4389 25.8618 17.5768 26.7239 16.5232 26.7239C15.4695 26.7239 14.6074 25.8618 14.6074 24.8082V9.48205C14.6074 8.42838 15.4695 7.56628 16.5232 7.56628ZM24.1862 18.5135C25.2399 18.5135 26.102 19.3756 26.102 20.4293V24.8082C26.102 25.8618 25.2399 26.7239 24.1862 26.7239C23.1325 26.7239 22.2704 25.8618 22.2704 24.8082V20.4293C22.2704 19.3756 23.1325 18.5135 24.1862 18.5135Z"
                    fill="#F02629"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_47_1684">
                    <rect
                      width="32.8417"
                      height="32.8417"
                      fill="white"
                      transform="translate(0.102539 0.724243)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <div className="ms-3">
              <span className="text-white fw-500">Sales</span>

              <h4 className="text-white">GMD &nbsp;{cashMonthlySale?.data?.monthlySales ?? 'Loading...'}</h4>
              <span className="text-white fw-500">Cash Power Monthly Sale</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mb-2">
          <div className=" d-flex br-20 align-items-center p-3" style={{ background: "#95b75d" }}>
            <span className="p-2 bg-light rounded-circle" >
              <svg
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_47_1684)">
                  <path
                    d="M8.8601 13.3136H9.13378C10.1875 13.3136 11.0495 14.1757 11.0495 15.2293V24.8082C11.0495 25.8618 10.1875 26.7239 9.13378 26.7239H8.8601C7.80643 26.7239 6.94434 25.8618 6.94434 24.8082V15.2293C6.94434 14.1757 7.80643 13.3136 8.8601 13.3136ZM16.5232 7.56628C17.5768 7.56628 18.4389 8.42838 18.4389 9.48205V24.8082C18.4389 25.8618 17.5768 26.7239 16.5232 26.7239C15.4695 26.7239 14.6074 25.8618 14.6074 24.8082V9.48205C14.6074 8.42838 15.4695 7.56628 16.5232 7.56628ZM24.1862 18.5135C25.2399 18.5135 26.102 19.3756 26.102 20.4293V24.8082C26.102 25.8618 25.2399 26.7239 24.1862 26.7239C23.1325 26.7239 22.2704 25.8618 22.2704 24.8082V20.4293C22.2704 19.3756 23.1325 18.5135 24.1862 18.5135Z"
                    fill="#F02629"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_47_1684">
                    <rect
                      width="32.8417"
                      height="32.8417"
                      fill="white"
                      transform="translate(0.102539 0.724243)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <div className="ms-3">
              <span className="text-white fw-500">Total Transactions</span>
              { /*<h4 className="text-white">23344.00</h4> */}
              <h4 className="text-white">  &nbsp;{tranCount?.data?.transactionCount ?? 'Loading...'}</h4>
              <span className="text-white fw-500">Cash Power</span>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2 row">
        {/* <div className="col-md-6 col-sm-12 mb-2">
          <div className="p-4 bg-white br-20">
            <div className="text-gray">
              <span className="bg-light px-2 py-2 rounded">
                <i className="bi bi-calendar"></i> Cash Power Daily Sales
              </span>
            </div>
            <div className="mt-5">
              <CashPowerDailySalesChart />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 mb-2">
          <div className="p-4 bg-white br-20">
            <div className="text-gray">
              <span className="bg-light px-2 py-2 rounded">
                <i className="bi bi-calendar"></i> Cash Power Daily Sales by
                location
              </span>
            </div>
            <div className="mt-5">
              <CashPowerDailySalesChartByLocation />
            </div>
          </div>
        </div> */}
      </div>
    </div >
  );
};

export default Dashboard;
