import { API_URL, http } from "./http.service";

 

export const userBalance = () => {

    try {
        return http.get(API_URL + 'vendorInfo');
    } catch (error) {
        return error;
    }
}